
<template>
    <div id="app">
        <div class="contain">
            <div class="cbody">
                <div class="content">
                    <div class="cure"></div>
                </div>
            </div>
            <foot></foot>
            <returnTop></returnTop>
        </div>
    </div>
</template>
<script>
    import foot from '/src/components/foot.vue';
    import returnTop from '/src/components/returnTop.vue';
    export default {
        name: 'actionLoad', //组件名字
        props: {
        },
        data: function () {
            return {
            }
        },
        components: {
            foot,
            returnTop
        }
    }
</script>
<style src="../style/onload.css" scoped>
    @import '../style/onload.css';
</style>