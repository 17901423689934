<template>
    <div id="app">
        <div class="contain" style="height: 100%;">
            <div class="footDiv" id="footDiv">
                <div>
                    <div class="footNav">
                        <!-- <div></div>  -->
                        <div @click="returnTop"> 首页</div>
                        <div class="aoi" @click="goPro">
                            <span>产品介绍</span>
                            <span>产品介绍</span>
                        </div>
                        <div class="aoi" @click="goExamp">
                            <span>案例展示</span>
                            <span>案例展示</span>
                        </div>
                        <div class="aoi" @click="goComp">
                            <span>公司简介</span>
                            <span>公司简介</span>
                        </div>
                        <div class="aoi" @click="goConUs">
                            <span>联系我们</span>
                            <span>联系我们</span>
                        </div>
                    </div>
                    <div :class="{'iao':footchang}"></div>
                    <div class="foot_ConUs">
                        <div>
                            <div>地址：腾讯烟台新工科研究院17楼</div>
                            <div class="waveStyle">
                                <div>电话：</div>
                                <span style="--i:1;">1</span>
                                <span style="--i:2;">8</span>
                                <span style="--i:3;">5</span>
                                &nbsp;
                                <span style="--i:4;">6</span>
                                <span style="--i:5;">1</span>
                                <span style="--i:6;">0</span>
                                <span style="--i:7;">8</span>
                                &nbsp;
                                <span style="--i:8;">8</span>
                                <span style="--i:9;">6</span>
                                <span style="--i:10;">1</span>
                                <span style="--i:11;">3</span>
                                &nbsp;
                                <div>(微信号同步)</div>
                            </div>
                            <div>邮箱：2890508872@qq.com</div>
                        </div>
                        <div><img src="../../img/logo02a.png" width="80%" /></div>
                    </div>
                </div>

                <div class="foot_copy">
                    <div style="cursor: pointer;" @click="goToIndustrialRecord">鲁ICP备2023025650号</div>
                    <div>鲁公网安备37069302001036号</div>
                    <div>版权所有：烟台衡润网络科技有限公司</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'foot', //组件名字
        data: function () {
            return {
                footchang: false,
            };
            components: {
                foot
            }
        },
        created() { //进页面执行的方法
            // this.aaa();
        },
        //页面加载完成后执行的方法
        mounted() {
            window.addEventListener('scroll', this.aaa, true);

        },
        methods: {
            goToIndustrialRecord() {
                // window.location.href = 'https://beian.miit.gov.cn';
                // window.open('https://beian.miit.gov.cn', '_blank');
                const link = document.createElement('a');
                link.href = 'https://beian.miit.gov.cn';
                link.target = '_blank';
                link.click();
            },
            returnTop() {
                this.$router.push({
                    name: 'index'
                })
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            },
            goPro() {
                this.$router.push({
                    name: 'productIntro'
                })
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            },
            goExamp() {
                this.$router.push({
                    name: 'exampIndex'
                })
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            },
            goComp() {
                this.$router.push({
                    name: 'aboutUs'
                })
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            },
            goConUs() {
                this.$router.push({
                    name: 'contactUs'
                })
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            },
            aaa() {
                var that = this;
                var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight; //变量windowHeight是可视区的高度
                var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight; //变量scrollHeight是滚动条的总高度
                if (scrollTop + windowHeight == scrollHeight) {
                    that.footchang = true;
                    return false;
                }
            }
        }
    }
</script>
<style src="../style/foot.css" scoped>
    @import '../style/foot.css';
</style>