<!-- 首页 -->
<template>
    <div id="app">
        <div class="contain">
            <div class="banner">
                <div><img src="../../img/logo02.png" width="100%" /></div>
                <div>{{title}}</div>
                <div></div> 
            </div>
            <div class="newsContent" v-html="newDetail.content">
                {{newDetail.content}}
            </div>
            <div class="xgXw">
                <div>相关新闻</div>
                <div></div>
                <div>
                    <div v-for="(xgxwItem,i) in xgXwList" @click="goNewsDetail(xgxwItem.id)">
                        <div><img :src="imgUrl+xgxwItem.path" width="100%" /></div>
                        <div>{{xgxwItem.title}}</div>
                    </div>
                </div>
            </div>
            <foot></foot>
            <navbar></navbar>
            <returnTop></returnTop>
        </div>
    </div>
</template>
<script>
    import navbar from '/src/components/nav.vue';
    import foot from '/src/components/foot.vue';
    import returnTop from '/src/components/returnTop.vue';
    export default {
        data: function () {
            return {
                newDetail: [],
                title: "",
                upDatatime: "",
                xgXwList: [],
                imgUrl: "",//新闻前缀
            };
        },
        created() { //进页面执行的方法
            var that = this;
            that.getDetail();
            that.getXgxw();
            this.imgUrl = this.$store.state.imgUrls
        },
        mounted() {

        },
        methods: {
            getDetail() {
                var that = this;
                var data = that.$route.query; //传过来的参数——id
                // console.log(data);
                $.ajax({
                    url: that.$store.state.urls + "news/getNews",
                    type: "post",
                    async:false,
                    data: {
                        id: data.id
                    },
                    dataType: "json",
                    success: function (val) {
                        that.newDetail = val.data;
                        that.title = val.data.title;
                        that.upDatatime = val.data.updateTime;
                    },
                });
            },
            goNewsDetail(id) {
                console.log(id);
                this.$router.push({
                    name: 'newsDetail',
                    query: {
                        id: id
                    }
                });
                this.$router.go(0);
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            },
            getXgxw() {
                var that = this;
                $.ajax({
                    url: that.$store.state.urls + "news/findNewsList",
                    type: "post",
                    headers: {
                        Accept: "application/json; charset=utf-8",
                        // Authorization: that.$store.state.changableNum
                    },
                    data: {
                        updateTime: that.upDatatime
                    },
                    dataType: "json",
                    success: function (val) {
                        that.xgXwList = val.data;
                        // console.log(that.xgXwList);
                    },
                });
            },
        },
        components: {
            foot,
            navbar,
            returnTop
        }
    }
</script>
<style src="../style/newsDetail.css" scoped>
    @import '../style/newsDetail.css';
</style>