<!-- 首页 -->
<template>
    <div id="app">
        <div class="contain">
            <div class="logoDIv">
                <img src="../../img/logo@2x.png" height="100%" />
            </div>
            <div class="ban" :style="bgStyle">
                <div class="active" :class="backImgIng">
                    <ul class="activeUl">
                        <li v-for="(menuItem,i) in indexMenu" v-on:mouseenter="changeImg(i)"
                            @click="goTo(menuItem.linkName)">
                            <div class="activeUlLiDiv">
                                <div class="activeTitle">{{menuItem.name}}</div>
                                <div class="activeContent">{{menuItem.intro}}</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="proDiv">
                <div class="proTitle">
                    <div>PRODUCT&nbsp;&nbsp;INTRODUCTION</div>
                    <div :class="{'chang':scrollIf1}"></div>
                    <div>衡润科技业务领域</div>
                </div>
                <div class="proShow">
                    <div class="pro_app" :style="bgStyle5">
                        <div class="circular"><i></i></div>
                        <div class="pro_app_text">
                            <div>
                                <div>APP开发</div>
                                <div>应用软件的制作与服务</div>
                            </div>
                            <div class="moreBtn" @click="goToExampApp">
                                <div>了解更多</div>
                                <div>
                                    <!-- <i class="el-icon-arrow-right"></i> -->
                                    <img src="../../img/more2.png" width="25px" height="25px" style="margin-top: 8px;"
                                        class="moreIcon" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pro_else">
                        <div class="pro_xcx" :style="bgStyle6">
                            <div class="circular"><i></i></div>
                            <div class="pro_xcx_text">
                                <div>
                                    <div>小程序开发</div>
                                    <div>一站式开发服务</div>
                                </div>
                                <div class="moreBtn" style="bottom: 10%;"@click="goToExampXcx">
                                    <div>了解更多</div>
                                    <div class="moreBtnAni">
                                        <img src="../../img/more2.png" width="25px" height="25px"
                                            style="margin-top: 8px;" class="moreIcon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pro_elseT">
                            <div class="pro_wzkf" :style="bgStyle7">
                                <div class="circular"><i></i></div>
                                <div class="pro_wzkf_text">
                                    <div>
                                        <div>网站开发</div>
                                        <div>个性化定制功能网站</div>
                                    </div>
                                    <div class="moreBtn" style="bottom: -10%;" @click="goToExampIndex">
                                        <div>了解更多</div>
                                        <div>
                                            <!-- <i class="el-icon-arrow-right"></i> -->
                                            <img src="../../img/more2.png" width="25px" height="25px"
                                                style="margin-top: 8px;" class="moreIcon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="qyxc" :style="bgStyle8">
                                <div class="circular"><i></i></div>
                                <div class="pro_qyxc_text">
                                    <div>
                                        <div>企业宣传片</div>
                                        <div>满足您的个性化品牌需求</div>
                                    </div>
                                    <div class="moreBtn" style="bottom: -10%;"@click="goToExampXcp">
                                        <div>了解更多</div>
                                        <div>
                                            <!-- <i class="el-icon-arrow-right"></i> -->
                                            <img src="../../img/more2.png" width="25px" height="25px"
                                                style="margin-top: 8px;" class="moreIcon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="proDiv">
                <div class="proTitle">
                    <div>SERVICE&nbsp;&nbsp;PROCESS</div>
                    <div :class="{'chang':scrollIf2}"></div>
                    <div>衡润科技服务流程</div>
                </div>
                <div class="serveShow">
                    <div class="serveProcess">
                        <div>
                            <div><div :class="{'down':scrollIf4}">规划</div></div>
                            <div><div :class="{'down':scrollIf4}">设计</div></div>
                            <div><div :class="{'down':scrollIf4}">开发</div></div>
                            <div><div :class="{'down':scrollIf4}">维护</div></div>
                        </div>
                    </div>
                    <div class="serveShow_t">
                        <div>
                            <div class="serveShow_t_Icon">
                                <img src="../../img/index/icon2.png" />
                            </div>
                            <div class="serveShow_t_T">规划</div>
                            <div class="serveShow_t_C">
                                <div>内容梳理&nbsp;结构策划</div>
                                <div>框架整合方案</div>
                            </div>
                        </div>
                        <div>
                            <div class="serveShow_t_Icon"><img src="../../img/index/icon4.png" /></div>
                            <div class="serveShow_t_T">设计</div>
                            <div class="serveShow_t_C">
                                <div>界面设计&nbsp;交互创意</div>
                                <div>响应式布局设计</div>
                            </div>
                        </div>
                        <div>
                            <div class="serveShow_t_Icon"><img src="../../img/index/icon1.png" /></div>
                            <div class="serveShow_t_T">开发</div>
                            <div class="serveShow_t_C">
                                <div>前端开发&nbsp;后端开发</div>
                                <div>技术调试发布</div>
                            </div>
                        </div>
                        <div>
                            <div class="serveShow_t_Icon"><img src="../../img/index/icon3.png" /></div>
                            <div class="serveShow_t_T">维护</div>
                            <div class="serveShow_t_C">
                                <div>网站内容更新</div>
                                <div>服务器日常维护</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="proDiv">
                <div class="proTitle">
                    <div>CROPORATE&nbsp;&nbsp;PHILOSOPHY</div>
                    <div :class="{'chang':scrollIf3}"></div>
                    <div>衡润科技公司理念</div>
                </div>
                <div class="pro_company">

                    <div>
                        <div class="pro_company_img">
                            <div :class="{'putInLeft':scrollIf5}"><img src="../../img/index/11.png" /></div>
                            <div :class="{'putInLeft':scrollIf5}" style="animation-delay: 0.5s;"><img
                                    src="../../img/index/4.png" /></div>
                        </div>
                        <div class="pro_company_content">
                            <div>
                                <div :class="{'putInRight':scrollIf5}">为企业提供信息化解决方案</div>
                                <div :class="{'putInRight':scrollIf5}" style="animation-delay: 0.5s;">努力让公司标准成为行业标准
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <foot></foot>
            <returnTop></returnTop>
            <navbar></navbar>
        </div>
    </div>
</template>
<script>
    import foot from './foot';
    import navbar from './nav';
    import returnTop from './returnTop';
    export default {
        props: {
        },
        data: function () {
            return {
                // backImg: require('../../img/index/1.png'),
                backImg: [],
                backImg1: "",
                backImg2: "",
                backImg3: "",
                backImg4: "",
                ywlyBackImgImg1: "",
                ywlyBackImgImg2: "",
                ywlyBackImgImg3: "",
                ywlyBackImgImg4: "",
                backImgIng:  require('../../img/index/1.png'),
                scrollIf1: false,
                scrollIf2: false,
                scrollIf3: false,
                scrollIf4: false,
                scrollIf5: false,
                indexMenu: [
                    {
                        name: "产品介绍",
                        intro: "Product Introduction",
                        linkName: "productIntro"
                    },
                    {
                        name: "案例展示",
                        intro: "Case Show",
                        linkName: "exampIndex"

                    },
                    {
                        name: "公司简介",
                        intro: "Company Profile",
                        linkName: "aboutUs"
                    },
                    {
                        name: "联系我们",
                        intro: "Contact Us",
                        linkName: "contactUs"
                    },
                ],
            }
        },
        created() { //进页面执行的方法
            var that = this;
            window.addEventListener("scroll", that.scroll);
            that.getBannerImg();
            that.getYwluImg();
        },
        mounted() {//加载完页面执行的方法
            var that = this;
        },
        methods: {
            getBannerImg() {
                // debugger;
                var that = this;
                $.ajax({
                    url: that.$store.state.urls + "home/findHomeImages",
                    type: "post",
                    headers: {
                        Accept: "application/json; charset=utf-8",
                        // Authorization: that.$store.state.changableNum
                    },
                    data: {},
                    dataType: "json",
                    success: function (val) {
                        that.backImg = val.data;
                        // console.log( "aaaaaaaaaaaaaaaaaaaaaaaaaaaaa",that.backImg);
                        that.backImg1 = that.$store.state.urls +"file"+ that.backImg[1];
                        that.backImg2 = that.$store.state.urls +"file"+ that.backImg[2];
                        that.backImg3 = that.$store.state.urls +"file"+ that.backImg[3];
                        that.backImg4 = that.$store.state.urls +"file"+ that.backImg[4];
                    },
                });
            },
            
            getYwluImg() {
                // debugger;
                var that = this;
                $.ajax({
                    url: that.$store.state.urls + "home/findHomeImages",
                    type: "post",
                    headers: {
                        Accept: "application/json; charset=utf-8",
                        // Authorization: that.$store.state.changableNum
                    },
                    data: {},
                    dataType: "json",
                    success: function (val) {
                        that.ywlyBackImgImg1 = that.$store.state.urls +"file"+ val.data[5];
                        that.ywlyBackImgImg2 = that.$store.state.urls +"file"+ val.data[6];
                        that.ywlyBackImgImg3 = that.$store.state.urls +"file"+ val.data[7];
                        that.ywlyBackImgImg4 = that.$store.state.urls +"file"+ val.data[8];
                    },
                });
            },
            goToExampApp(){
                this.$router.push({
					name: 'exampApp'
				})
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            },
            
            goToExampXcx(){
                this.$router.push({
					name: 'exampXcx'
				})
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            },
            goToExampIndex(){
                this.$router.push({
					name: 'exampIndex'
				})
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            },
            goToExampXcp(){
                this.$router.push({
					name: 'exampXcp'
				})
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            },
            scroll() {
                var that = this;
                var scrollTop = document.documentElement.scrollTop;
                if (scrollTop >= 400 && scrollTop <= 600) {
                    that.scrollIf1 = true;
                } else if (scrollTop >= 1300 && scrollTop <= 1500) {
                    that.scrollIf2 = true;
                } else if (scrollTop >= 1500 && scrollTop <= 1800) {
                    that.scrollIf4 = true;
                } else if (scrollTop >= 2400 && scrollTop <= 2700) {
                    that.scrollIf3 = true;
                } else if (scrollTop >= 2700 && scrollTop <= 3100) {
                    that.scrollIf5 = true;
                }
            },
            changeImg(i) {
                var that = this;
                switch (i) {
                    case 0:
                        that.backImgIng = that.backImg1
                        console.log("111",that.backImgIng);
                        break;
                    case 1:
                        that.backImgIng = that.backImg2
                        // console.log(that.backImg1);
                        break;
                    case 2:
                        that.backImgIng = that.backImg3
                        // console.log(that.backImg1);
                        break;
                    default:
                        that.backImgIng = that.backImg4
                        // console.log(that.backImg1);
                        break;
                }
            },
            goTo(linkName) {
                // console.log(linkName);
                this.$router.push({
                    name: linkName
                })
            },
        },
        components: {
            foot,
            navbar,
            returnTop
        },
        computed: {
            bgStyle() {
                var that = this;
                return {
                    backgroundImage: 'url(' + that.backImgIng + ')'
                }
            },
            bgStyle5() {
                var that = this;
                return {
                    backgroundImage: 'url(' + that.ywlyBackImgImg1 + ')'
                }
            },
            bgStyle6() {
                var that = this;
                return {
                    backgroundImage: 'url(' + that.ywlyBackImgImg2 + ')'
                }
            },
            bgStyle7() {
                var that = this;
                return {
                    backgroundImage: 'url(' + that.ywlyBackImgImg3 + ')'
                }
            },
            bgStyle8() {
                var that = this;
                return {
                    backgroundImage: 'url(' + that.ywlyBackImgImg4 + ')'
                }
            }
        }
    }
</script>
<style src="../style/index.css" scoped>
    @import '../style/index.css';
</style>