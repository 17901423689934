<!-- 首页 -->
<template>
    <div id="app">
        <div class="contain">
            <div class="logoDIv">
                <img src="../../img/logo@2x.png" height="100%" />
            </div>
            <div class="banner">
                <div>
                    <div>
                        <div class="bannerContend">APP开发</div>
                    </div>
                    <div>
                        <div class="bannerContend">小程序开发</div>
                    </div>
                    <div>
                        <div class="bannerContend">网站开发</div>
                    </div>
                    <div>
                        <div class="bannerContend">企业宣传片</div>
                    </div>
                </div>
                <div>
                    <div>产品介绍</div>
                    <div><img src="../../img/productIntro/text.png" width="80%" style="margin: auto;" /></div>
                </div>
            </div>
            <div class="introShow">
                <div>
                    <div><img src="../../img/productIntro/1.png" width="10%"></div>
                    <div>
                        <div><img src="../../img/index/5.png" width="100%" height="100%" /></div>
                        <div>
                            <div>APP开发</div>
                            <div>APP功能强大，可实现的功能多，给用户更好的体验；</br>
                                APP基于手机的随时随身性的特点，容易通过微信，微博等方式分享和传播，实现裂变式增长；</br>
                                APP的开发成本，相比传统营销手段成本更低；</br>
                                通过新技术以及数据分析，APP可实现精准定位企业目标用户，实现低成本快速增长成为可能；</br>
                                用户手机安装APP以后，企业即可持续与用户保持联系。</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div><img src="../../img/productIntro/2.png" width="10%"></div>
                    <div>
                        <div><img src="../../img/index/8.png" width="100%" height="100%" /></div>
                        <div>
                            <div>小程序开发</div>
                            <div>用户群体更广泛，坐拥巨大的流量池，更利于商家引流扩客；</br>
                                用户体验好，不需要下载安装，几乎不占手机内存，也不会影响手机运行速度，即用即走；</br>
                                小程序开发门槛和成本低，一次开发就能适用于所有机型；</br>
                                依托于微信平台，运营推广容易。</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div><img src="../../img/productIntro/3.png" width="10%"></div>
                    <div>
                        <div><img src="../../img/index/7.png" width="100%" height="100%" /></div>
                        <div>
                            <div>网站开发</div>
                            <div>
                                方便快捷：网站可以随时随地访问，不受时间和空间限制；</br>
经济高效：相比传统的宣传和广告手段，网站可以节省大量的成本和人力资源；</br>
交流互动：网站可以提供丰富的用户交流和互动功能；</br>
资源共享：网站不仅为用户建立了信息和娱乐平台；</br>
多元化：网站可以提供多个主题和领域...
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div><img src="../../img/productIntro/4.png" width="10%"></div>
                    <div>
                        <div><img src="../../img/index/6.png" width="100%" height="100%" /></div>
                        <div>
                            <div>企业宣传片</div>
                            <div>信息量大：一部3分钟左右的企业宣传片可以向客户传递大量信息；</br>
                                性价比高：单看一部企业宣传片的制作费用似乎并不便宜，但企业宣传片可以在很多场合使用；</br>
                                提高宣传效率：有了企业宣传片，企业在面对客户时不必只依靠图片或文字向客户介绍企业信息；</br>
                                方便产品宣传：企业宣传片能够简单清晰的阐释明白内容，因此十分适合新产品的宣传。</div>
                        </div>
                    </div>
                </div>
            </div>
            <foot></foot>
            <navbar></navbar>
            <returnTop></returnTop>
        </div>

    </div>
</template>
<script>
    import navbar from '/src/components/nav.vue';
    import foot from '/src/components/foot.vue';
    import returnTop from '/src/components/returnTop.vue';
    export default {
        data: function () {
            return {
            };
        },
        created() { //进页面执行的方法
            // this.aaa();
        },
        mounted() {

        },
        methods: {
        },
        components: {
            foot,
            navbar,
            returnTop
        }
    }
</script>
<style src="../style/productIntro.css" scoped>
    @import '../style/productIntro.css';
</style>