<!-- 首页 -->
<template>
    <div id="app">
        <div class="contain">
            <div class="title">联系我们</div>
            <div class="contactUs">
                <div>
                    <div>烟台衡润网络科技有限公司</div>
                    <div><img src="../../img/contactUs/phone.png" class="phoneIcon" /> &nbsp;
                        <span>185&nbsp;6108&nbsp;8613</span>
                    </div>
                    <div>邮箱：2890508872@qq.com</div>
                    <div>地址：腾讯烟台新工科研究院B栋17楼</div>
                    <div><img src="../../img/qrCode.png" /></div>
                    <div><img src="../../img/contactUs/text.png" width="50%" /></div>
                </div>
                <div>
                    <!-- <div></div> -->
                    <!-- <div><img src="../../img/contactUs/ing.png"/><img src="../../img/logo@2x.png"/></div> -->
                </div>

            </div>
            <navbar></navbar>
        </div>
    </div>
</template>
<script>
    import navbar from '/src/components/nav.vue';
    export default {
        data: function () {
            return {

            };
        },
        created() { //进页面执行的方法
            // this.aaa();
        },
        mounted() {

        },
        methods: {
        },
        components: {
            navbar,
        },
    }
</script>
<style src="../style/contactUs.css" scoped>
    @import '../style/contactUs.css';
</style>