
<template>
    <div id="app">
        <div class="contain" style="height: 100%;">
            <div class="TopDiv" @click="returnTop">
                <img src="../../img/ruturnTop3.png" width="100%" height="100%"/>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'returnTop', //组件名字
        props: {
        },
        data: function () {
            return {
            };
            components: {
                returnTop
            }
        },
        created() { //进页面执行的方法
            // this.aaa();
        },
        methods: {
            returnTop(){
                document.body.scrollTop=document.documentElement.scrollTop=0;
            }
        }
    }
</script>
<style src="../style/returnTop.css" scoped>
    @import '../style/returnTop.css';
</style>