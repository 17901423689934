<template>
    <div id="app">
        <div class="contain">
            <div class="logoDIv">
                <img src="../../img/logo@2x.png" height="100%" />
            </div>
            <div class="aboutUs_First">
                <div>
                    <div>
                        <div>公司简介</div>
                        <div>
                            烟台衡润网络科技有限公司立项于国务院（国发〔2020〕8号）文件，并在烟台市开发区经济发展和科技创新局的支持下入驻腾讯烟台新工科研究院。</br>
                            核心团队均积累了互联网行业多年开发经验，服务过的客户类型有：智慧校园，工业制作，科技社区，能源环保等众多领域，具备优秀的开发技术以及职业素养。
                            </br>公司专注为客户根据企业形象精准定位，提供专业的策划与开发，助力企业实现数字化转型。
                        </div>
                    </div>
                    <div style="z-index: 1;"></div>
                </div>
                <div></div>
            </div>
            <div class="aboutUs_Plate">
                <div class="aboutUs_Title">
                    <div></div>
                    <div>ABOUT&nbsp;走进衡润</div>
                    <div></div>
                </div>
                <div class="aboutUs_SSalutation" :class="{'salutionEaseIn':salutionEaseIn}">我们能做什么</div>
                <div class="aboutUs_SShow">
                    <div style="align-items: end;">
                        <div :class="{'showEaseIn1':showEaseIn}">
                            <div>APP开发</div>
                        </div>
                        <div :class="{'showEaseIn2':showEaseIn}">
                            <div>网站开发</div>
                        </div>
                    </div>
                    <!--  <p>YAN TAI HENG RUN WNAG LUO KE JI YOU XIAN GONG SI</p> -->
                    <div>
                        <div><img src="../../img/exampImg/1.png" width="75%" height="75%" /></div>
                        <div>
                            <img src="../../img/logo03.png" width="35%" height="55%" />
                        </div>
                    </div>
                    <div style="align-items: start;">
                        <div :class="{'showEaseIn3':showEaseIn}">
                            <div>小程序开发</div>
                        </div>
                        <div :class="{'showEaseIn4':showEaseIn}">
                            <div>企业宣传片</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="aboutUs_Plate2">
                <div class="aboutUs_Title">
                    <div></div>
                    <div>NEWS&nbsp;新闻动态</div>
                    <div></div>
                </div>
                <div class="newsList">
                    <div class="news" v-for="(NewsItem,i) in nowNewList" @click="goNewsDetail(NewsItem.id)">
                        <div class="newsImg"><img :src="imgUrl+NewsItem.path" width="100%" height="100%" /></div>
                        <div class="newsTitle">{{NewsItem.title}}</div>
                        <div class="newsOs">
                            <div><span>查看详情</span><img src="../../img/more.png" width="18px"
                                    height="22px" class="moreIcon" /></div>
                            <div>{{NewsItem.updateTime}}</div>
                        </div>
                    </div>
                </div>
                <div class="aboutUs_TPage">
                    <el-pagination small background layout="total, prev, pager, next" :total="total" class="mt-4"
                        :page-size="pageSize" :page-sizes="[2, 4, 6, 10]" @current-change="handleCurrentChange"
                        :pager-count="5" />
                </div>
            </div>
            <foot></foot>
            <returnTop></returnTop>
            <navbar></navbar>
        </div>
    </div>
</template>
<script>
    import foot from '/src/components/foot.vue';
    import navbar from '/src/components/nav.vue';
    import returnTop from '/src/components/returnTop.vue';
    export default {
        props: {
        },
        data: function () {
            return {
                salutionEaseIn: false,
                showEaseIn: false,
                newsList: [],
                nowNewList: [],//正显示的新闻列表
                total: 0,//总数
                currentPage: 1,//当前页数
                pageSize: 6,//，每页显示多少条
                imgUrl: "",//新闻前缀
            }
        },
        created() { //进页面执行的方法
            var that = this;
            // window.addEventListener("scroll", that.scroll);
            // that.imgUrl = that.store.$state.imgUrls;

        },
        mounted() {
            var that = this;
            that.getList();
            that.getNowNewsList();
            this.imgUrl = this.$store.state.imgUrls
        },
        methods: {
            getList() {
                var that = this;
                $.ajax({
                    url: that.$store.state.urls + 'news/findAllNews',
                    type: "post",
                    async: false,
                    data: {
                    },
                    dataType: "json",
                    success: function (val) {
                        
                        that.newsList = val.data;
                        that.total = that.newsList.length;
                    },
                });
            },
            getNowNewsList() {
                var that = this;
                let begin = (that.currentPage - 1) * that.pageSize;
                let end = that.currentPage * that.pageSize;
                that.nowNewList = that.newsList.slice(begin, end);
                // console.log(that.nowNewList);
            },
            //换页
            handleCurrentChange(val) {
                // console.log(val);
                var that = this;
                that.currentPage = val;
                that.getNowNewsList();
            },
            scroll() {
                var that = this;
                var scrollTop = document.documentElement.scrollTop;
                if (scrollTop >= 300 && scrollTop <= 500) {
                    that.salutionEaseIn = true;
                    that.showEaseIn = true;
                }
            },
            goNewsDetail(id) {
                this.$router.push({
                    name: 'newsDetail',
                    query: {
                        id: id
                    }
                });
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            }
        },
        components: {
            foot,
            navbar,
            returnTop
        },
    }
</script>
<style src="../style/aboutUs.css" scoped>
    @import '../style/aboutUs.css';
</style>