<template>

  <router-view>
  </router-view>
</template>
<script>
  export default {
    props: {
    },
    data: function () {
      return {
      }
    },
    created() { //进页面执行的方法
      var that = this;
    },
    methods: {
    },
    components: {
    },
    computed: {
    }
  }
  // import ElementView from './views/element/ElementView.vue'
</script>
<style lang="scss">
  #app {
    /* border: 1px solid green; */
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-align: center; */
    position: relative;
    /* height: 100vh; */
    /* overflow-y: scroll;
    scroll-snap-type: y mandatory; */
  }

  * {
    margin: 0;
    padding: 0;
  }
  .logoDIv {
    width: 100%;
    height: 10vh;
    margin-top: 10px;
    margin-bottom: 8px;
}

  ul {
    list-style: none;
  }

  li {
    display: inline-block;
  }

  /* body{
  overflow: hidden;
} */
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;

    background-color: #00000040;

  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(28, 21, 101, 0.5);
    border-radius: 10px;
    /* display: none; */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    display: none;

    background-color: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }

  .contain {
    width: 100%;
    height: 98vh;
    text-align: center;
    color: white;
    font-family: "SimSun, serif";

    box-sizing: border-box;
    /* scroll-snap-align: center; */
  }

  nav {
    padding: 30px;
    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
</style>