import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
//如果果采用脚手架方式进行创建vuex，可以不用去app.vue里配置，人家都配好了你写变量之间用就行了
//store是仓库的意思，可以理解为存放全局变量的地方，语句含义为创建一个全局变量仓库
export default new Vuex.Store({
  //含义为创建一个存东西的对象
  state: {
    //声明全局变量
    urls: 'http://110.42.223.2:8080/hrst/',
    imgUrls: 'http://110.42.223.2:8080/hrst/file',
  },

  getters: {

  },
  //操作修改这个具体值的方法，因为用现实中仓库举例，需要有一个工具来搬运这个货物。如果想把全局变量值给改了要从这里改
  mutations: {
    updateName(urls) {
      state.urls = urls
    },
    updateName2(imgUrls) {
      state.imgUrls = imgUrls
    }
  },

  // 操作异步操作mutation   暂时应该应该用不到
  actions: {
  },
  modules: {
  }
})
