var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"contain"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"aboutUs_Plate"},[_vm._m(2),_c('div',{staticClass:"aboutUs_SSalutation",class:{'salutionEaseIn':_vm.salutionEaseIn}},[_vm._v("我们能做什么")]),_c('div',{staticClass:"aboutUs_SShow"},[_c('div',{staticStyle:{"align-items":"end"}},[_c('div',{class:{'showEaseIn1':_vm.showEaseIn}},[_c('div',[_vm._v("APP开发")])]),_c('div',{class:{'showEaseIn2':_vm.showEaseIn}},[_c('div',[_vm._v("网站开发")])])]),_vm._m(3),_c('div',{staticStyle:{"align-items":"start"}},[_c('div',{class:{'showEaseIn3':_vm.showEaseIn}},[_c('div',[_vm._v("小程序开发")])]),_c('div',{class:{'showEaseIn4':_vm.showEaseIn}},[_c('div',[_vm._v("企业宣传片")])])])])]),_c('div',{staticClass:"aboutUs_Plate2"},[_vm._m(4),_c('div',{staticClass:"newsList"},_vm._l((_vm.nowNewList),function(NewsItem,i){return _c('div',{staticClass:"news",on:{"click":function($event){return _vm.goNewsDetail(NewsItem.id)}}},[_c('div',{staticClass:"newsImg"},[_c('img',{attrs:{"src":_vm.imgUrl+NewsItem.path,"width":"100%","height":"100%"}})]),_c('div',{staticClass:"newsTitle"},[_vm._v(_vm._s(NewsItem.title))]),_c('div',{staticClass:"newsOs"},[_vm._m(5,true),_c('div',[_vm._v(_vm._s(NewsItem.updateTime))])])])}),0),_c('div',{staticClass:"aboutUs_TPage"},[_c('el-pagination',{staticClass:"mt-4",attrs:{"small":"","background":"","layout":"total, prev, pager, next","total":_vm.total,"page-size":_vm.pageSize,"page-sizes":[2, 4, 6, 10],"pager-count":5},on:{"current-change":_vm.handleCurrentChange}})],1)]),_c('foot'),_c('returnTop'),_c('navbar')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logoDIv"},[_c('img',{attrs:{"src":require("../../img/logo@2x.png"),"height":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutUs_First"},[_c('div',[_c('div',[_c('div',[_vm._v("公司简介")]),_c('div',[_vm._v(" 烟台衡润网络科技有限公司立项于国务院（国发〔2020〕8号）文件，并在烟台市开发区经济发展和科技创新局的支持下入驻腾讯烟台新工科研究院。"),_c('br'),_vm._v(" 核心团队均积累了互联网行业多年开发经验，服务过的客户类型有：智慧校园，工业制作，科技社区，能源环保等众多领域，具备优秀的开发技术以及职业素养。 "),_c('br'),_vm._v("公司专注为客户根据企业形象精准定位，提供专业的策划与开发，助力企业实现数字化转型。 ")])]),_c('div',{staticStyle:{"z-index":"1"}})]),_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutUs_Title"},[_c('div'),_c('div',[_vm._v("ABOUT 走进衡润")]),_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('img',{attrs:{"src":require("../../img/exampImg/1.png"),"width":"75%","height":"75%"}})]),_c('div',[_c('img',{attrs:{"src":require("../../img/logo03.png"),"width":"35%","height":"55%"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutUs_Title"},[_c('div'),_c('div',[_vm._v("NEWS 新闻动态")]),_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("查看详情")]),_c('img',{staticClass:"moreIcon",attrs:{"src":require("../../img/more.png"),"width":"18px","height":"22px"}})])
}]

export { render, staticRenderFns }