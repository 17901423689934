<!-- 初始加载动画组件 -->
<template>
    <div id="app">
        <div class="contain">
            <div class="bgStyle">
                <div class="square">
                    <span></span>
                    <span></span>
                    <span></span>
                    <div class="content">
                        <div>
                            <img src="../../img/logo01.gif" width="50%" height="45%" />
                            <h2>衡润网络科技公司</h2>
                        </div>
                        <p>欢迎来到企业一站式管理的"快车道"，在这数字化的时代，我们将为您打造最佳解决方案…</p>
                        <div class="a" @click="goIndex">Read More</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'actionLoad01', //组件名字
        props: {
        },
        data: function () {
            return {
            }
        },
        created() { //进页面执行的方法
            // this.aaa();
        },
        mounted() {

        },
        methods: {
            goIndex(){
                var that=this;
				that.$router.push({
					name: 'index',
					// query: {
					// 	scode: 17
					// }
				})
            }
        }
    }
</script>
<style src="../style/actionAnima.css" scoped>
    @import '../style/actionAnima.css';
</style>