import Vue from 'vue'
import VueRouter from 'vue-router'
import ActionDemo from '../views/onloadView.vue'
import demo2 from '../views/actionAnima.vue'
import index from '../components/index.vue'
import mouseFollower from '../views/mouseFollow.vue'
import foot from '../components/foot.vue'
import nav from '../components/nav.vue'
import returnTop from '../components/returnTop.vue'
import exampIndex from '../views/exampIndex.vue'
import exampApp from '../views/exampApp.vue'
import exampXcx from '../views/exampXcx.vue'
import exampXcp from '../views/exampXcp.vue'
import newsDetail from '../views/newsDetail.vue'
import aboutUs from '../views/aboutUs.vue'
import contactUs from '../views/contactUs.vue'
import productIntro from '../views/productIntro.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
 
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [//导航
  {
    path: '/nav',
    name: 'nav',
    component:nav
  },
  //加载动画
  {
    path: '/ActionDemo',
    name: 'ActionDemo',
    component:ActionDemo
  },
  
  //联系我们
  {
    path: '/contactUs',
    name: 'contactUs',
    component:contactUs
  },
  //產品介紹
  {
    path: '/productIntro',
    name: 'productIntro',
    component:productIntro
  },
  //新闻详情
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component:newsDetail
  },
  //案例展示
  {
    path: '/exampIndex',
    name: 'exampIndex',
    component:exampIndex
  },
  //案例展示_APP
  {
    path: '/exampApp',
    name: 'exampApp',
    component:exampApp
  },
  //案例展示_小程序
  {
    path: '/exampXcx',
    name: 'exampXcx',
    component:exampXcx
  },
  //案例展示_宣传片
  {
    path: '/exampXcp',
    name: 'exampXcp',
    component:exampXcp
  },
  //公司简介
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component:aboutUs
  },
  //加载动画
  {
    path: '/returnTop',
    name: 'returnTop',
    component:returnTop
  },
  //开屏动画
  {
    path: '/demo2',
    name: 'demo2',
    component:demo2
  },
  //首页
  {
    path: '/',
    name: 'index',
    component:index
  },
  //鼠标跟随动画
  {
    path: '/mouseFollower',
    name: 'mouseFollower',
    component:mouseFollower
  },
  //页足
  {
    path: '/foot',
    name: 'foot',
    component:foot
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
