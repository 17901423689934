<!-- 首页 -->
<template>
    <div id="app">
        <div class="">
            <div @click="showNav" :class="{'showNav':showNava}" class="show">
                <img src="../../img/nav.png" width="35px" height="35px" />
            </div>
            <div @click="CloseNav" :class="{'showNav':CloseNava}" class="close">
                <img src="../../img/close.png" width="35px" height="35px">
            </div>
            <div class="nav" :class="{'showNav':IfShow}">
                <div>
                    <div class="aoi" @click="goIndex">
                        <span>首页</span>
                        <span>首页</span>
                    </div>
                    <div class="aoi" @click="goPro">
                        <span>产品介绍</span>
                        <span>产品介绍</span>
                    </div>
                    <div class="aoi" @click="goExamp">
                        <span>案例展示</span>
                        <span>案例展示</span>
                    </div>
                    <div class="aoi" @click="goComp">
                        <span>公司简介</span>
                        <span>公司简介</span>
                    </div>
                    <div class="aoi" @click="goConUs">

                        <span>联系我们</span>
                        <span>联系我们</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'navbar', //组件名字
        data: function () {

            return {
                showNava: false,
                CloseNava: true,
                IfShow: true,
            };
            components: {
                navbar
            }
        },
        created() { //进页面执行的方法
            // this.aaa();
        },
        //页面加载完成后执行的方法
        mounted() {

        },
        methods: {
            goIndex() {
                this.$router.push({
                    name: 'index'
                })
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            },
            goPro() {
                this.$router.push({
                    name: 'productIntro'
                })
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            },
            goExamp() {
                this.$router.push({
                    name: 'exampIndex'
                })
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            },
            goComp() {
                this.$router.push({
                    name: 'aboutUs'
                })
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            },
            goConUs() {
                this.$router.push({
                    name: 'contactUs'
                })
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            },
            showNav() {
                var that = this;
                that.showNava = true;
                that.CloseNava = false;
                that.IfShow = false;
            },
            CloseNav() {
                var that = this;
                that.showNava = false;
                that.CloseNava = true;
                that.IfShow = true;
            }
        }
    }
</script>
<style src="../style/nav.css" scoped>
    @import '../style/nav.css';
</style>