import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {Icon} from 'element-ui';

import index from './utilize/index.js'//首页
import rain from './views/mouseFollow.vue'//鼠标跟随
import foot from './components/foot.vue'
import navbar from './components/nav.vue'
import returnTop from './components/returnTop.vue'


Vue.use(index);
Vue.use(foot);
Vue.use(rain);
Vue.use(navbar);
Vue.use(returnTop);
Vue.use(ElementUI);
Vue.use(Icon);
// Vue.component('foot',foot);
Vue.config.productionTip = false

// 在 main.js 或 App.js 中
Vue.mixin({
  mounted() {
    this.$nextTick(() => {
      const images = this.$el.querySelectorAll('img');
      images.forEach(img => {
        if (!img.getAttribute('loading')) {
          img.setAttribute('loading', 'lazy');
        }
      });
    });
  }
});


new Vue({
  router,
  store,
  foot,
  navbar,
  returnTop,
  render: h => h(App)
}).$mount('#app')
