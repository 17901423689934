<template>
    <div id="app">
        <div class="contain">
            <div class="logoDIv">
                <img src="../../img/logo@2x.png" height="100%" />
            </div>
            <!-- <div :style="{ height: waveAllHeight + 'px', background: bgColor }" class="wave banner">
                <canvas id="wave1"></canvas>
            </div> -->
            <div class="banner">
                <div>
                    <div>小程序开发</div>
                    <div>小程序的开发</div>
                    <div>
                        <div>
                            <div>软件应用</div>
                            <div>在线下载</div>
                        </div>
                        <div>
                            <div>使用管理</div>
                            <div>软件活动</div>
                        </div>
                        <div> 
                            <div>会员沉淀</div>
                            <div>数据分析</div>
                        </div>
                        <div>
                            <div>应用介绍</div>
                            <div>在线使用</div>
                        </div>
                    </div>
                    <div>
                        <img src="../../img/qrCode2.png" width="20%" />
                    </div>
                </div>
                <div>
                    <div>
                        <div><img src="../../img/exampImg/exampxcx.png" /></div>
                        <!-- <div><img src="../../img/index/1.png" /></div>
                        <div><img src="../../img/index/7.png" /></div>
                        <div><img src="../../img/index/4.png" /></div> -->
                    </div>
                </div>
            </div>
            <!-- <div class="cube-container">
                <div class="cube">
                  <div class="face front"><img src="front.jpg" alt="Front"></div>
                  <div class="face back"><img src="back.jpg" alt="Back"></div>
                  <div class="face right"><img src="right.jpg" alt="Right"></div>
                  <div class="face left"><img src="left.jpg" alt="Left"></div>
                  <div class="face top"><img src="top.jpg" alt="Top"></div>
                  <div class="face bottom"><img src="bottom.jpg" alt="Bottom"></div>
                </div>
              </div> -->
            <div class="exampShow">
                <div v-for="(exampAppItem,i) in exampAppList">
                    <div><img :src="imgUrl+exampAppItem.image" width="100%" height="100%" /></div>
                    <div>
                        <div></div>
                        <div>{{exampAppItem.title}}</div>
                        <div>
                            <!-- <div> -->
                            <div>
                                <div>{{exampAppItem.content}}</div>
                            </div>
                            <!-- </div> -->
                        </div>
                        <div><img :src="imgUrl+exampAppItem.qrCode" width="25%" height="100%" /></div>
                    </div>
                </div>
            </div>
            <foot></foot>
            <navbar></navbar>
            <returnTop></returnTop>
        </div>
    </div>
</template>
<script>
    import navbar from '/src/components/nav.vue';
    import foot from '/src/components/foot.vue';
    import returnTop from '/src/components/returnTop.vue';
    export default {
        // props: {
        //     bgColor: { default: 'color' }, // 背景色
        //     waveAllHeight: { default: 200 }, // 波浪的整体高度
        //     waveCount: { default: 3 }, // 波峰个数
        //     waveHeight: { default: 50 }, // 波浪起伏高度
        //     // 波浪颜色
        //     waveColor: {     
        //         default() {
        //             return [1500, 3000, 2500];
        //         },
        //     },
        // },
        data: function () {
            return {
                imgUrl: "",//新闻前缀
                exampAppList: []
            }
        },
        created() { //进页面执行的方法
            var that = this;
        },
        mounted() {
            var that = this;
            that.imgUrl = that.$store.state.imgUrls
            that.getList();
            // this.wavePlay("wave1", 140, this.waveColor[0], this.waveTime[0]);
            // this.wavePlay("wave2", 140, this.waveColor[1], this.waveTime[1]);
            // this.wavePlay("wave3", 140, this.waveColor[2], this.waveTime[2]);
        },
        methods: {
            getList() {
                var that = this;
                $.ajax({
                    // url: that.$store.state.urls + "casePresentation/findAllCasePresentation",
                    url: that.$store.state.urls + "casePresentation/findOtherCasePresentation",
                    
                    type: "post",
                    async: false,
                    data: {
                        type: 1
                    },
                    dataType: "json",
                    success: function (val) {
                        console.log(val);
                        that.exampAppList=val;
                        // var list=val.data;
                        // for (var i=0; i < list.length; i++) {
                        //     if (list[i].type==1) {
                        //         that.exampAppList.push(list[i]);
                        //     }
                            
                        // }
                        // console.log(that.exampAppList);
                    },
                });
            },
            // wavePlay($canvasID, $progress, $maveColor, $time) {
            //     console.log($canvasID);
            //     console.log($progress);
            //     console.log($maveColor);
            //     console.log($time);
            //     const that = this;
            //     let waveWidth = 3300, // 波浪长度
            //         offset = 0,
            //         waveHeight = that.waveHeight, // 波浪起伏高度
            //         waveCount = that.waveCount, // 波浪个数
            //         startX = -1000,
            //         startY = 212, // canvas 高度
            //         progress = $progress, // 波浪位置高度
            //         d2 = waveWidth / waveCount, // 单个波浪的宽度
            //         d = d2 / 2,
            //         hd = d / 2,
            //         c = document.getElementById($canvasID),
            //         ctx = c.getContext("2d");
            //     c.width = 1920; // 画布宽度
            //     c.height = that.waveAllHeight; // 画布高度
            //     function move() {
            //         offset -= 5;
            //         if (-1 * offset === d2) {
            //             offset = 0;
            //         }
            //         ctx.clearRect(0, 0, c.width, c.height);
            //         ctx.fillStyle = $maveColor; // 画布填充色
            //         ctx.beginPath();
            //         let offsetY = startY - progress;
            //         // 绘制贝塞尔曲线
            //         ctx.moveTo(startX - offset, offsetY); // 开始点
            //         for (let i = 0; i < waveCount; i++) {
            //             let dx = i * d2;
            //             let offsetX = dx + startX - offset;
            //             ctx.quadraticCurveTo(
            //                 offsetX + hd,
            //                 offsetY + waveHeight,
            //                 offsetX + d,
            //                 offsetY
            //             );
            //             ctx.quadraticCurveTo(
            //                 offsetX + hd + d,
            //                 offsetY - waveHeight,
            //                 offsetX + d2,
            //                 offsetY
            //             );
            //         }
            //         ctx.lineTo(startX + waveWidth, 3000);
            //         ctx.lineTo(startX, 3000);
            //         ctx.fill();
            //         setTimeout(move, $time / 60); // 速度
            //     }

            //     move();
            // },
        },
        components: {
            foot,
            navbar,
            returnTop
        },
    }
</script>
<style src="../style/exampXcx.css" scoped>
    @import '../style/exampXcxShow.css';
</style>