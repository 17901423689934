<!-- 首页 -->
<template>
    <div id="app">
        <div class="contain">
        </div>

    </div>
</template>
<script>
    // import 'css-doodle';

    export default {
        name1: 'rain', //组件名字
        data: function () {
            return {
                count: 0,
            };
            components: {
            }
        },
        created() { //进页面执行的方法
            // this.aaa();
        },
        mounted() {

        },
        methods: {
        }
    }
</script>
<style src="../style/mouseFollow.css" scoped>
    @import '../style/mouseFollow.css';
</style>