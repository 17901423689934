<template>
    <div id="app">
        <div class="contain">
            <div class="exampIName">
                案例展示
                <div class="text">HENG&nbsp;RUN</div>
            </div>
            <div class="exampIMap">
                <img src="../../img/exampImg/map.png" width="100%" height="100%" />
                <div class="exampIconShow1 exampIconShow">
                    <div></div>
                </div>
                <div class="exampIconShow1Text exampIconShowText">济南</div>
                <div class="exampIconShow2 exampIconShow">
                    <div></div>
                </div>
                <div class="exampIconShow2Text exampIconShowText">青岛</div>
                <div class="exampIconShow3 exampIconShow">
                    <div></div>
                </div>
                <div class="exampIconShow3Text exampIconShowText">烟台</div>
                <div class="exampIconShow4 exampIconShow">
                    <div></div>
                </div>
                <div class="exampIconShow4Text exampIconShowText">德州</div>
                <!-- <div class="exampIconShow1"><div></div></div>
                    <div class="exampIconShow1Text exampIconShowText">威海</div> -->
                <div class="exampIconShow5 exampIconShow">
                    <div></div>
                </div>
                <div class="exampIconShow5Text exampIconShowText">洛阳</div>
                <div class="exampIconShow6 exampIconShow">
                    <div></div>
                </div>
                <div class="exampIconShow6Text exampIconShowText">铜陵</div>
                <div class="exampIconShow7 exampIconShow">
                    <div></div>
                </div>
                <div class="exampIconShow7Text exampIconShowText">茂名</div>
                <div class="exampIconShow8 exampIconShow">
                    <div></div>
                </div>
                <div class="exampIconShow8Text exampIconShowText">河源</div>
                <div class="exampIconShow9 exampIconShow">
                    <div></div>
                </div>
                <div class="exampIconShow9Text exampIconShowText">六盘水</div>
                <div class="exampIconShow10 exampIconShow">
                    <div></div>
                </div>
                <div class="exampIconShow10Text exampIconShowText">遵义</div>
                <div class="exampIconShow11 exampIconShow">
                    <div></div>
                </div>
                <div class="exampIconShow11Text exampIconShowText">常德</div>


                <div class="exampMapText1">衡润科技&nbsp;位于美丽烟台</div>
                <div class="exampMapText2">但我们的业务不止于烟台</div>
            </div>
            <div class="mouseAnima">
                <div><img src="../../img//exampImg/mouseIcon.png" width="40px" height="40px" /></div>
                <div></div>
            </div>
            <div class="elseExamp">
                <div>企业网站建设案例</div>
                <div @click="goToExampXcx">小程序建设案例</div>
                <div @click="goToExampApp">APP建设案例</div>
                <div @click="goToExampXcp">宣传片建设案例</div>
            </div>
            <div class="exampShow">
                <div v-for="(exampItem,i) in examp">
                    <div><img :src="imgUrl+exampItem.path" width="100%" height="100%" /></div>
                    <div>
                        <div>
                            <!-- 名字 -->
                            <div>
                                <div>{{exampItem.title}}</div>
                                <div>{{exampItem.content}}</div>
                            </div>
                            <!-- Icon -->
                            <div @click="goWebsite(exampItem.connect)"><img src="../../img/exampImg/lianjie.png" width="15px" height="15px" /></div>
                        </div>
                    </div>
                </div>
            </div>

            <foot></foot>
            <navbar></navbar>
            <returnTop></returnTop>
        </div>
    </div>
</template>
<script>
    import navbar from '/src/components/nav.vue';
    import foot from '/src/components/foot.vue';
    import returnTop from '/src/components/returnTop.vue';
    export default {
        props: {
        },
        data: function () {
            return {
                examp: [],
                imgUrl: "",//新闻前缀 
            }
        },
        created() { //进页面执行的方法
        },
        mounted() {
            this.getNewList();
            this.imgUrl = this.$store.state.imgUrls
        },
        methods: {
            getNewList() {
                var that = this;
                $.ajax({
                    url: that.$store.state.urls + "product/findAllProduct",
                    type: "post",
                    // async: false,
                    data: {
                    },
                    dataType: "json",
                    success: function (val) {
                        console.log(val);
                        // console.log(val.data);
                        that.examp = val.data;
                        // console.log(that.examp);
                    },
                });
            },
            goWebsite(connect){
                window.location.href = connect;
            },
            goToExampApp() {
                this.$router.push({
                    name: 'exampApp'
                })
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            },
            goToExampXcx() {
                this.$router.push({
                    name: 'exampXcx'
                })
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            },
            goToExampXcp() {
                this.$router.push({
                    name: 'exampXcp'
                })
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            },
        },
        components: {
            foot,
            navbar,
            returnTop
        },
    }
</script>
<style src="../style/exampIndex.css" scoped>
    @import '../style/exampIndex.css';
</style>